@import "../../../../../common.less";

.outerBox {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.leftBox {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-right: 1px solid rgba(214,235,235,0.18);
  flex-shrink: 0;
  flex-grow: 0;
  background: linear-gradient( 90deg, #141627 0%, #1D1F33 100%);
  padding-top: 10px;
}

.leftTopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
  margin-bottom: 10px;
}
.leftTitle{
  color: #EEEFFC;
  font-size: 16px;
  font-weight: 500;
}
.addOneBtn {
  cursor: pointer;
  font-size: 14px;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  opacity: 1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.10) 100%);
  box-shadow: 0px 0.5px 1px 0px rgba(9, 9, 47, 0.80), 0px 0.5px 0.5px 0px rgba(255, 255, 255, 0.12) inset;

  &:hover {
    opacity: 0.65;
  }
}

.listShow {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: auto;
  padding: 0 10px 10px;
  justify-content: space-between;
  align-content: flex-start;
}

.emptyMsg {
  width: 100%;
  margin-top: 100px;
}

.chartItemBox {
  min-width: 35%;
  flex: 1;
  border-radius: 4px;
  overflow: hidden;
  background: #242254;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid transparent;

  cursor: pointer;
}

.chartItemBoxSel {
  .chartItemBox;
  border: 1px solid #843CFF;
}

.chartItemBoxEmpty {
  .chartItemBox;
  height: 0;
  box-shadow: none;
  background: none;
  border: 1px solid transparent;
}

.chartTitle {
  padding: 4px 8px;
}

//自定义组件的封面可以手动生成，比例不固定，采用拉伸方式展示
.chartCover {
  width: 100%;
  height: 100px;
  //background-size: cover;
  //background-size: contain;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.rightTopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(214,235,235,0.18);
}

.rightTitle {
  font-size: 16px;
}

.rightCenter {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
}

.paramsBox {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-right: 1px solid rgba(214,235,235,0.18);
  flex-shrink: 0;
  flex-grow: 0;
}

.viewBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.viewBoxHeader {
  padding: 10px;
  text-align: center;
}
.viewBoxFooter{
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
.viewBoxBody{
  flex: 1;
  position: relative;
  border-top: 1px solid rgba(214,235,235,0.18);
  border-bottom: 1px solid rgba(214,235,235,0.18);
}
.paramsContentBody{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 10px;
}
.paramsContentFooter{
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  gap: 10px;
}
.chartOptionBox{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  background-color: #04042d;
  z-index: 999;
}