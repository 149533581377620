.vdoBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.videoBgStyle{
  width: 100%;
  height: 100%;
}