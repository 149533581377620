.outerBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tabLine{
  display: flex;
  align-items: center;
  background-color: rgba(132, 60, 255, 0.1);
  padding: 0px;
  margin: 5px 0;
  border-bottom: 4px dashed transparent;
  border-top: 4px dashed transparent;

  text-align: center;
  &:hover{
    background-color: rgba(132, 60, 255, 0.3);
  }
}
.eachCardBox{
  overflow: hidden;
  position: relative;
}