@import "../../../common.less";

.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  word-break: break-all;
}
.nameStyle{
  font-size: 26px;
}
.btn{
  margin: 30px 10px;
  min-width: 150px;
}
.item{
  padding: 5px 0;
}
.label{
  text-align: right;
  padding-right: 20px;
  color: #333;
}
.labelRequire{
  text-align: right;
  color: #333;

  &:before{
    content: '*';
    color: #e4393c;
  }
}
.value{
  display: inline-block;
}
.returnBtn{
  min-width: 120px;
  margin: 20px 10px;
}
.btnBox{
  text-align: center;
  margin: 80px auto 0;

  .btn{
    margin: 0 30px;
    min-width: 120px;
  }
}
.btnDelete{
  .btnDelete;
  display: inline-block;
  float: right;
}
.progressOut{
  box-sizing: border-box;
  width: 100%;
  height: 10px;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, rgb(255, 227, 0) 0%, rgb(0, 255, 63) 100%);
  background: -moz-linear-gradient(bottom, #ffe300, #00ff3f);
  background: -o-linear-gradient(bottom, #ffe300, #00ff3f);
  background: -webkit-gradient(linear, 0% 0%, 100% 100%, from(#ffe300), to(#00ff3f));

  .progressIn{
    float: right;
    height: 8px;
    background-color: #fff;
  }
}

.paramBox{
  border: 1px solid #29A6FF;
  border-radius: 3px;
  padding: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  .paramTitle{
    color: #29A6FF;
    font-weight: bold;
  }
  .delBtn{
    position: absolute;
    top: 0;
    right: 0;
    color: #e4393c;
    cursor: pointer;
  }
  .editBtn{
    position: absolute;
    top: 0;
    right: 40px;
    color: #29A6FF;
    cursor: pointer;
  }
}
.addParam{
  float: right;
}