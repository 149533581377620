.outer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #01C2FF22;
  margin-top: 10px;
  gap: 15px;
  color: #DEF0FFFF;
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 250;
}
.head .headCol {
  flex: 1;
  position: relative;
  height: 22px;
}
.head .headColTxt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
}
.body {
  flex: 1;
  position: relative;
}
.bodyScroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow: overlay;
  color: #A8D6FFAA;
}
.bodyScroll::-webkit-scrollbar {
  display: none;
}
.bodyScroll .bodyRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 10px 15px;
  margin: 0 0;
  font-size: 13px;
  font-weight: 250;
  border: 2px solid transparent;
}
.bodyScroll .clickLineStyle:hover {
  cursor: pointer;
  border: 2px solid;
  background: linear-gradient(270deg, rgba(16, 40, 73, 0) 0%, #102849 51%, rgba(16, 40, 73, 0) 100%);
  border-image: linear-gradient(270deg, rgba(93, 144, 194, 0), #5d90c2, rgba(93, 144, 194, 0)) 2 2;
}
.bodyScroll .bodyCol {
  position: relative;
  flex: 1;
}
.bodyBefore {
  position: relative;
}
.bodyAfter {
  position: relative;
  overflow: hidden;
}
