.tableBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  user-select: text;

  :global{
    .ant-table{
      background: transparent !important;
    }
    .ant-table-title{
      background: unset !important;;
    }
    .ant-table-thead > tr > th{
      background-color: unset !important;
    }
    .ant-table-tbody > tr > td{
      border-bottom: unset !important;;
      border-right: unset !important;
    }
  }
}
.table{
  clear: both;
  width: 100%;
  text-align: center;
}
.tableTitle{
  font-weight: bold;
  font-size: 18px;
}
.table thead tr{
  //background-color: #06306a;
  border: 1px solid #fff;
}
.table th,.table tr,.table td{
  text-align: center!important;
  border: 1px solid #005f99;
}
.tbTitle{

  //&:hover{
  //  .deleteCol{
  //    display: inline;
  //  }
  //}
}

.table thead th{
  &:hover{
    .deleteCol{
      display: inline-block;
      margin-left: 5px;
    }
  }
}
.deleteCol{
  color: #e9071a;
  display: none;

  cursor: pointer;
}
.preValueShow{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-all;
}