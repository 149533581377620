@import "../../../common.less";

.mainPage{
  box-sizing: border-box;
  padding: 20px;
  padding-top: 50px;
  overflow: hidden;
}
.btnBox{
  margin: 30px auto;
  text-align: center;
}

.btn{
  display: inline-block;
  margin-top: 50px;
  margin-right: 20px;
  min-width: 150px;
}
.submitBtn{
  .btn;
}
.cancelBtn{
  .btn;
}