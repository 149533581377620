@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.controlBar{
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row-reverse;
}

.card {
  min-width: 136px;
  min-height: 76px;
  width: 50%;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(246, 198, 249, 0.4) 0%,
    rgba(164, 164, 218, 0.4) 100%
  );
}

.chartBox{
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}