@import "../../../common.less";
.mainPage{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
.outerLayoutBox{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #19193c;

  :global{
    .ant-drawer .ant-collapse-content{
      background-color: unset !important;
    }
    .ant-collapse-content > .ant-collapse-content-box{
      padding: 4px;
    }

    .swiper{
      //--swiper-theme-color: #ff00ff;/* 设置Swiper风格 */
      //--swiper-navigation-color: #00ff33;/* 单独设置按钮颜色 */
      //--swiper-navigation-size: 80px;/* 设置按钮大小 */document.getElementsByClassName('swiper-button-next')[0].style.setProperty('--swiper-navigation-color', 'blue');
    }
    .swiper-button-next{
      opacity: 0.3;
    }
    .swiper-button-next:hover{
      opacity: 1;
    }
    .swiper-button-prev{
      opacity: 0.3;
    }
    .swiper-button-prev:hover{
      opacity: 1;
    }
    .swiper-scrollbar-drag{
      opacity: 0.3;
    }
    .swiper-scrollbar-drag:hover{
      opacity: 1;
    }
    .swiper-pagination-current{
      font-family: inherit;
    }
    .swiper-pagination-total{
      font-family: inherit;
    }
  }
}
.mainHeadBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1f2333;
  font-size: 14px;
}
.thumbsBox{
  position: relative;
}
.mainBodyBox{
  flex: 1;
  position: relative;
  order: 5
}
.thumbsItem{
  padding: 10px 20px;
  cursor: pointer;
  background-color: #67cfff;
}
.thumbsItemAct{
  .thumbsItem;
  color: red;
  background-color: #88e996;
}
.tabPaneContainer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.swiperPaneContainer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.leftControlBar{
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #2e3244;
}
.dashboardContent{
  flex: 1;
  position: relative;
  overflow: overlay;
  overflow: auto;
  z-index: 1;
}
.gridBg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dashboardContentHasBg{
  .gridBg;
  background-image: linear-gradient(
          90deg,
          rgba(33, 233, 252, 0.15) 1%,
          rgba(0, 0, 0, 0) 5%
  ),
  linear-gradient(rgba(33, 233, 252, 0.15) 1%, rgba(0, 0, 0, 0) 5%);
  background-size: 28px 28px;
}
.dashboardContentHasBgMobile{
  .gridBg;
  background-image: linear-gradient(
          90deg,
          rgba(248, 163, 85, 0.25) 1%,
          rgba(0, 0, 0, 0) 5%
  ),
  linear-gradient(rgba(248, 163, 85, 0.25) 1%, rgba(0, 0, 0, 0) 5%);
  background-size: 28px 28px;
}
.resizableBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: overlay;
  overflow: auto;
  z-index: 1;
  border: 1px solid transparent;

  &:focus{
    border: 1px dashed rgba(132,30,255,0.65);
  }
}
.disResizableBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;
  border: 1px solid transparent;

  &:focus{
    border: 1px dashed rgba(132,30,255,0.65);
  }
}
.leftControlBtn{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  margin: 4px 0;
  user-select: none;

  &:hover span,&:hover div{
    color: #843CFF;
  }
}

.contentBox{
  border: 2px dashed #1e90ff3f;
}
.contentBoxForDy{
  border: 2px dashed #ff8c003f;
}
.selContentBox{
  border: 2px dashed limegreen;
}
.connectContentBox{
  border: 2px solid #843CFF;
}
.contentSetBarMark{
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}
.contentSetBar{
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.contentSetBtn{
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  cursor: pointer;

  &:hover{
    color: #843CFF;
  }
}
.dynamicContentSetBox{
  width: 300px;
  background-color: #2e3244;
}
.rightSideTabPaneBox{
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  overflow: auto;
  overflow: overlay;
  overflow-x: hidden;

  & ::-webkit-scrollbar{
    z-index: 999;
  }
}
.tabSelName{
  font-size: 18px;
  line-height: 18px;
  padding: 0 5px;
  & div,& span{
    color: #843CFF;
  }
}
.tabNameNormal{
  padding: 0 5px;
  font-size: 14px;
  line-height: 12px;
}
.thumbsContainerS{
  :global{
    .swiper-wrapper{
      justify-content: flex-start;
    }
  }
}
.thumbsContainerC{
  :global{
    .swiper-wrapper{
      justify-content: center;
    }
  }
}
.thumbsContainerE{
  :global{
    .swiper-wrapper{
      justify-content: flex-end;
    }
  }
}
.swiperTitleBox{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}
.sizeMsg{
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 5px;
  font-size: 16px;
  z-index: 99;
}
.leftViewSelBox{
  padding: 10px;
  width: 600px;
  max-height: 60vh;
  overflow: auto;
}
.parameterUidShow{
  position: absolute;
  top: -20px;
  left: 0;
  opacity: 0.5;
}
.file_uploader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  outline: none;
  opacity: 0;
  cursor: pointer;
}