.errorMsg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: darkorange;
  text-align: center;
}
.chartBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chartDom{
  width: 100%;
  height: 100%;
}