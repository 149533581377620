.outerBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.msgContainer{
  width: 100%;

  & div,& span{
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: inherit;
  }
}
.msgBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.msgBoxForLine{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.msgInner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.msgInnerForLine{
  display: flex;
  align-items: center;
  gap: 10px;
}