@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chartBox{
  width: 100%;
  height: 100%;
}
.barBox{
  flex: 1;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ffffff33;
}
.maleBar{
  background-color: #ffbd2b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.femaleBar{
  .maleBar;
  background-color: #bb8cf9;
  justify-content: flex-start;
}