.mainBox{
  box-sizing: border-box;
  margin: 20px auto;
  border-radius: 5px;
}
.saleInfo{
  text-align: center;
  padding: 30px;
  height: 130px;
}
.infoItem{
  display: inline-block;
  min-width: 120px;
  padding: 0 30px;
  border-right: 1px solid #ccc;

  &:last-child{
    border: none;
  }
}
.itemTitle{
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.specialItem{
  .itemTitle;
  color: #67cfff;
  cursor: pointer;

  &:hover{
    font-weight: bold;
  }
}
.itemDetail{
  font-size: 22px;
  color: #333;
}
.countBox{
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 800px;
  min-height: 400px;
  padding-top: 20px;
}
.countBox02{
  .countBox;
  padding-top: 0;
}
.dateSel{
  margin: 10px 0 0 10px;
}
.dateSel02{
  color: #333;
}