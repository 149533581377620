@import "../../../common.less";

.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  padding-top: 50px;
  word-break: break-all;
}
.contentBox{
  min-height: 300px;
  padding: 30px;
  word-wrap: break-word;
}
.item{
  padding: 5px 0;
}
.label{
  text-align: right;
  padding-right: 20px;
  color: #333;
}
.value{
  display: inline-block;
}
.videoStyle{
  width: 100%;
}
.returnBtn{
  width: 150px;
  margin: 30px 0;
}
.logContainer{
  box-sizing: border-box;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  padding: 10px;
  max-height: 600px;
  overflow: scroll;
}
.logItem{
  margin-bottom: 15px;

  .dateStr{
    color: #7f7f7f;
    font-size: small;
  }


  .logStyle{
    padding-left: 20px;
  }

  .stringType {
    color: green;
  }

  .numberType {
    color: darkorange;
  }

  .booleanType {
    color: blue;
  }

  .nullType {
    color: magenta;
  }

  .keyType {
    color: #191919;
  }
}
.errorTxt{
  color: red;
}