@import "../../../common.less";

.mainPage{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  word-break: break-all;
}
.totalMsg{
  font-size: 20px;
  font-weight: bold;

  .number{
    padding: 0 10px;
  }
}
.btn{
  margin: 30px 10px;
  min-width: 150px;
}
.item{
  padding: 5px 0;
}
.label{
  text-align: right;
  padding-right: 20px;
  color: #333;
}
.value{
  display: inline-block;
}
.returnBtn{
  min-width: 120px;
  margin: 20px 10px;
}
.toolBar{
  float: left;
}
.toolBtn{
  margin-right: 10px;
}
.deviceFirst{
  text-align: left;

  .itemLabel{
    display: inline-block;
    width: 70px;
    text-align: right;
    color: #29A6FF;
  }
}
.stringType {
  color: green;
}

.numberType {
  color: darkorange;
}

.booleanType {
  color: blue;
}

.nullType {
  color: #ff3d08;
}

.keyType {
  color: #191919;
}