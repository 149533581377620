@import '../../ContentView.less';

.outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.tabsBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.tabPaneBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
