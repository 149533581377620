@import "../../../../common.less";

.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}
.extraMsg{
  text-align: left;
  .tableLabel{
    display: inline-block;
    width: 140px;
    padding-right: 10px;
    text-align: right;
  }
}
.nameStyle{
  font-size: 26px;
}
.item{
  margin: 10px auto;
}
.btnBox{
  text-align: center;
  margin: 80px auto 0;

  .btn{
    margin: 0 30px;
    min-width: 120px;
  }
}
.label{
  text-align: right;
  color: #666;

  &:before{
    content: '*';
    color: #e4393c;
  }
}
.btnDelete02{
  .btnDelete;
  display: inline-block;
  float: right;
}
.stringType {
  color: green;
}

.numberType {
  color: darkorange;
}

.booleanType {
  color: blue;
}

.nullType {
  color: magenta;
}

.keyType {
  color: red;
}