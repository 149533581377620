@import "../../../common.less";

.mainPage{
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}
.extraMsg{
  text-align: left;
  .tableLabel{
    display: inline-block;
    width: 150px;
    padding-right: 10px;
    text-align: right;
  }
}
.nameStyle{
  font-size: 26px;
}
.btnBox{
  text-align: center;
  margin: 80px auto 0;

  .btn{
    margin: 0 30px;
    min-width: 120px;
  }
}
.label{
  text-align: right;
  color: #666;

  &:before{
    content: '*';
    color: #e4393c;
  }
}
.btnDelete02{
  .btnDelete;
  display: inline-block;
  float: right;
}

.progressOut{
  box-sizing: border-box;
  width: 100%;
  height: 10px;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, rgb(255, 227, 0) 0%, rgb(0, 255, 63) 100%);
  background: -moz-linear-gradient(bottom, #ffe300, #00ff3f);
  background: -o-linear-gradient(bottom, #ffe300, #00ff3f);
  background: -webkit-gradient(linear, 0% 0%, 100% 100%, from(#ffe300), to(#00ff3f));

  .progressIn{
    float: right;
    height: 8px;
    background-color: #fff;
  }
}

.popDiv{
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 80vw;
  max-height: 80vh;
  overflow: auto;
}
.radioLabel{
  display: inline-block;
  width: 40vw;
  margin: 25px 0;
  font-size: 20px;

  &:hover{
    color: dodgerblue;
    font-weight: bold;
  }
}
.tipTxt{
  color: darkorange;
  font-size: 20px;
  line-height: 24px;
}