.outerBox{
  display: flex;
}
.beforeMsg{

}
.centerMsg{

}
.afterMsg{

}