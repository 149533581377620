@import "../../../../../../common.less";
.outerBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow: overlay;
  padding: 10px;

  &::-webkit-scrollbar{
    display: none;
  }

  &:hover::-webkit-scrollbar{
    display: unset;
    z-index: 99;
  }
}
.timeItem{
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 0.01rem;
}
.insertActionBox{
  position: relative;
  overflow: visible;
  opacity: 1;
  left: 0;
  .animation(insert 0.8s ease-in-out forwards);
}
.insertActionBoxRev{
  position: relative;
  opacity: 1;
  overflow: visible;
  right: 0;
  .animation(insertRev 0.8s ease-in-out forwards);
}
.keyframes(insert,{
  0% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
});
.keyframes(insertRev,{
  0% {
    right: 200px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
});
.timeEndMark{
  height: 0px;
}