.outerBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.contentBox {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: stretch;
}
.nameBox {
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.imgBoxTwo {
  overflow: hidden;
  position: relative;
  width: 100%;
  flex: 1;
}
.imgPos {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
