@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.chartBox{
  width: 100%;
  height: 100%;
}
.controlBar{
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row-reverse;
}

.chartOuter{
  flex: 1;
  position: relative;
}
.chartOuterHide{
  height: 0;
  overflow: hidden;
}
.countMsgBox{
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: stretch;
}
.topMsgBox{
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex: 1;
  margin-bottom: 10px;
}