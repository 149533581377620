@import '../lib/file/upFile/style/index.less';
@import './Layout.less';
@import "../lib/imageViewer/viewer/style/index.less";

.outerBox{
  :global{
    .ant-form-item{
      margin-bottom: 10px !important;
    }
  }
}