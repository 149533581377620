.mainPage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #202232;
}
.tabsBox {
  height: 100%;
}
.tabPaneBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
