@import "../../common.less";

.labelCloudOuter{
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  overflow: hidden;
}
.labelCloudShineOne{
  .animation(shineForCloud 1s ease-in-out);
}
.labelCloudShineTwo{
  .animation(shineForCloud2 1s ease-in-out);
}
.labelCloudContainer{
  width: 100%;
  height: 100%;
}
.emptyBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}