.outerBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
  overflow: overlay;

  &::-webkit-scrollbar{
    display: none;
  }

  &:hover::-webkit-scrollbar{
    display: unset;
    z-index: 999;
  }
}
.eachBox{
  flex-direction: column;
 position: relative;
}
.emptyBox{
  height: 0;
  flex: 1;
}
.imgBox{
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  position: relative;
}
.nameBox{
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.eachBoxTwo{
  flex-direction: column;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.imgBoxTwo{
  .imgBox;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.imgPos{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}