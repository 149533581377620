@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tabsBox{
  width: 100%;
  height: 100%;
  position: relative;
}
.tabPaneBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}