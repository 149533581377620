.outerBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow: overlay;

  &::-webkit-scrollbar{
    display: none;
  }

  &:hover::-webkit-scrollbar{
    display: unset;
    z-index: 999;
  }
}
.listBox{
  display: flex;
  width: 100%;
  flex-direction: column;
}
.headMsgBox{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.onelineBox{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.barBorderContainer{
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
  border-style: solid;
}
.barContainer{
  flex: 1;
  position: relative;
  overflow: hidden;
}
.innerBar{
  position: absolute;
  top: 0;
  bottom: 0;
}
.itemErr{
  margin: 10px 0;
  color: darkorange;
}