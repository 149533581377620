@import "../../common.less";
.main {
  position: absolute;
  width: 100%;
  height:100%;
  background-color: #fff;
  background-size:100% 100%;
}
.loading {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
}
.loading_img{
 width: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  //animation: spin 2s linear infinite;
  //transform:rotate(0deg);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  -moz-animation:  spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform:rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform:rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform:rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform:rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform:rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform:rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform:rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform:rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
.anim{
  position: absolute;
  width: 100%;
  height:100%;
}
.big_box{
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 500px;
  height: 550px;
  text-align: center;
  padding-top: 60px;
  background-color: rgba(250,255,255,0.9);
  border-radius: 10px;
}

.title{
  margin-bottom:62px ;
  font-size: 30px;
  font-weight: bold;
  color: #666;
  letter-spacing: 2px;
}

.box {
  width: 460px;
  height: 350px;
  min-height: 300px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 30px;
}

@inputColor:#aaa;

.input{
  & input{
    background-color:#fff;
    font-size: 16px;
    color: #333;
    width:350px;
    height: 40px;
    padding-left: 10px;
    padding-right: 40px;
  }
}
.input::-webkit-input-placeholder{
  color:@inputColor;
}
.input:-moz-placeholder{
  color:@inputColor;
}
.input::-moz-placeholder{
  color:@inputColor;
}
.input:-ms-input-placeholder{
  color:@inputColor;
}
.input:-webkit-autofill {
  color: @inputColor;
  caret-color: @inputColor;
  border-radius: 2px;
}

.input1{
  & input{
    font-size: 16px;
    background-color:#fff;
    color:#333;
    text-fill-color:@inputColor;
    -webkit-text-fill-color:@inputColor;
    width:350px;
    height: 40px;
    padding-left: 10px;
    padding-right: 40px;
  }
}
.input1:-webkit-autofill {
  color: @inputColor;
  caret-color: @inputColor;
  border-radius: 2px;
}
.input1::-webkit-input-placeholder{
  color:@inputColor;
}
.input1:-moz-placeholder{
  color:@inputColor;
}
.input1::-moz-placeholder{
  color:@inputColor;
}
.input1:-ms-input-placeholder{
  color:@inputColor;
}

.img1{
  width:24px;
  height:24px;
  position: absolute;
  margin-left: -35px;
  margin-top: 13px;
}
.img2{
  width:24px;
  height:24px;
  position: absolute;
  margin-left: -35px;
  margin-top: 33px;
}

.Spin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login {
  margin-top: 30px;
}

.h2 {
  margin-bottom: 35px;
  color: #666;
  font-size: 18px;
}

.btn{
  width:400px;
  min-height:40px;
  background-color: @primary-color;
  color: #fff;
  font-size: 16px;
  margin-top: 70px;

  &:hover{
    background-color: @primary-color;
    color: #fff;
    font-size: 17px;
  }
}

.img{
  position: absolute;
  width:16px;
  left: 60px;
  height:16px;
  margin-top: 18px;
}
.remember{
  position: absolute;
  font-size: 14px;
  margin-top: 15px;
  color: @primary-color;
  margin-left: 25px;
}
.error{
  position: absolute;
  font-size: 12px;
  margin-top: 17px;
  left: 50px;
  color: red;
}
.bottom{
  position: absolute;
  bottom: 20px;
  margin: auto;
  left: 0;
  right: 0;
}
.bottomText {
  font-size: 14px;
  color: #999;
  text-align: center;
}
.bottom1 {
  margin-top: 3px;
  font-size: 12px;
  color: @primary-color;
  opacity: 0.4;
  text-align: center;
}
.icon{
  width: 40px;
  font-size: 20px;
}
.block{
  height: 25px;
}
.forgetPassword{
  position: absolute;
  right: 50px;
  margin-top: 25px;
  font-size: 14px;
  color: #29A6FF;
  cursor: pointer;
}
.contactText{
  font-size: 16px;
}