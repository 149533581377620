@import "../../../common.less";

.mainPage {
  box-sizing: border-box;
  overflow: hidden;
}

.formContainer {
  padding: 30px;
  max-width: 1200px;
}

.big {
  box-sizing: border-box;
  border: 1px solid #aaa;
  border-radius: 5px;
  min-width: 200px;
  min-height: 200px;
  float: left;
  margin: 0 10px 10px 0 !important;
}

.btn {
  margin-top: 50px;
  margin-right: 20px;
  min-width: 150px;
}

.submitBtn {
  .btn;
}

.cancelBtn {
  .btn;
}

.label {
  text-align: right;
  color: rgba(0, 0, 0, 0.85);

  &:before {
    display: inline-block;
    margin-right: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f04134;
  }
}

.treeBox {
  margin: 10px 0;
}

.inputTitle {
  font-size: 32px;
  margin-bottom: 20px;
}

.inputTitle2 {
  font-size: 12px;
  margin-bottom: 5px;
}
