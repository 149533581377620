@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.cardBg {
  width: 100%;
  /* height: 100%; */
  background-repeat: 'no-repeat';
  background-size: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(246, 198, 249, 0.4) 0%,
    rgba(164, 164, 218, 0.4) 100%
  );
  margin-top: 16px;
}