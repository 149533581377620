.numberAnimationWrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.numberAnimationWrap div {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  font-style: inherit;
}
.numberAnimationWrap .numberAnimation {
  position: absolute;
  left: 0;
  top: 0;
  height: auto;
  transform-origin: 0 0;
  transition: top 0.2s;
}
.numberAnimationWrap .number,
.numberAnimationWrap .numberAnimationWrapHidden {
  font-weight: bold;
  text-align: center;
}
.numberAnimationWrap .numberAnimationWrapHidden {
  visibility: hidden;
}
