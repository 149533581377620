@import "../../../../../../common.less";

.container {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: rgba(0,0,0,0.5);
  pointer-events: auto;
}
.deviceContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-content: flex-start;
}

.mapShowBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
}

.img{
  width: 0;
  height: 0;
  opacity: 0;
}
.outerForRobotInfo{
  width: 100%;
  height: 80vh;
}
.mayLoadLayer{
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2e303f;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
.mayLoadLayerHide{
  .mayLoadLayer;
  opacity: 0;
  transition: opacity 0.3s;
}

@keyframes ldio-10d8267j8wu {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-10d8267j8wu div { box-sizing: border-box!important }
.ldio-10d8267j8wu > div {
  position: absolute;
  width: 154.77px;
  height: 154.77px;
  top: 38.115px;
  left: 38.115px;
  border-radius: 50%;
  border: 6.93px solid #000;
  border-color: #5b6ee1 transparent #5b6ee1 transparent;
  animation: ldio-10d8267j8wu 1.282051282051282s linear infinite;
}

.ldio-10d8267j8wu > div:nth-child(2), .ldio-10d8267j8wu > div:nth-child(4) {
  width: 136.29px;
  height: 136.29px;
  top: 47.355000000000004px;
  left: 47.355000000000004px;
  animation: ldio-10d8267j8wu 1.282051282051282s linear infinite reverse;
}
.ldio-10d8267j8wu > div:nth-child(2) {
  border-color: transparent #6af8ea transparent #6af8ea
}
.ldio-10d8267j8wu > div:nth-child(3) { border-color: transparent }
.ldio-10d8267j8wu > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-10d8267j8wu > div:nth-child(3) div:before, .ldio-10d8267j8wu > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6.93px;
  height: 6.93px;
  top: -6.93px;
  left: 66.99px;
  background: #5b6ee1;
  border-radius: 50%;
  box-shadow: 0 147.84px 0 0 #5b6ee1;
}
.ldio-10d8267j8wu > div:nth-child(3) div:after {
  left: -6.93px;
  top: 66.99px;
  box-shadow: 147.84px 0 0 0 #5b6ee1;
}

.ldio-10d8267j8wu > div:nth-child(4) { border-color: transparent; }
.ldio-10d8267j8wu > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-10d8267j8wu > div:nth-child(4) div:before, .ldio-10d8267j8wu > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6.93px;
  height: 6.93px;
  top: -6.93px;
  left: 57.75px;
  background: #6af8ea;
  border-radius: 50%;
  box-shadow: 0 129.36px 0 0 #6af8ea;
}
.ldio-10d8267j8wu > div:nth-child(4) div:after {
  left: -6.93px;
  top: 57.75px;
  box-shadow: 129.36px 0 0 0 #6af8ea;
}
.loadingio-spinner-double-ring-v0383conw4g {
  width: 231px;
  height: 231px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-10d8267j8wu {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-10d8267j8wu div { box-sizing: content-box; }



//地图标签样式
.markerBox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:hover{
    .markerLine{
      display: none;
    }
    .pointBox{
      display: none;
    }
  }
}
.markerNameBox{
  color: #fff;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 50px;
  min-width: 50px;
  text-align: center;
  position: relative;
  white-space: nowrap;

  .markerNum{
    display: none;
  }

  &:hover{
    text-align: left;
    padding: 8px 12px;
    border-radius: 8px;

    .markerNum{
      display: block;
    }
  }

}
.markerLine{
  height: 45px;
  width: 2px;
}
.pointBox{
  position: relative;
  width: 0;
  height: 0;
  //background-color: #fff;
  .center{
    position: absolute;
    top: 50%;
    left: 50%;
    .transform(translate(-50%,-50%));
    width: 8px;
    height: 4px;
    background-color: #00FFFF;
    border-radius: 50%;
    opacity: 1;
  }
  .firstRoll{
    position: absolute;
    top: 50%;
    left: 50%;
    .transform(translate(-50%,-50%));
    width: 8px;
    height: 4px;
    border: 2px solid #00FFFF;
    border-radius: 50%;
    opacity: 1;
    .animation(firstRoll 2.4s ease-in-out infinite);
  }
  .secondRoll{
    .firstRoll;
    .animation(firstRoll 2.4s ease-in-out infinite 1.1s);
  }
}
.keyframes(firstRoll,{
  0% {
    opacity: 1;
    width: 8px;
    height: 4px;
    border-width: 2px;
  }
  100% {
    opacity: 0;
    width: 36px;
    height: 18px;
    border-width: 1px;
  }
});

.legendBox{
  z-index: 9;
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 2px;
  background: rgba(0,0,0,0.35);
  padding: 10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px 0px;
}

.legendItem{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}
.colorItem{
  width: 16px;
  height: 10px;
  border-radius: 2px;
}
.colorTitle{
  font-size: 12px;
  color: #fff;
  word-break: keep-all;
  white-space: nowrap;
}