@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.controlBar{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}
.chartBox{
  width: 100%;
  height: 100%;
}