.title {
  font-size: larger;
  font-weight: bold;
  margin: 15px;
}
.col {
  display: inline-block;
  min-width: 150px;
  width: 24%;
}
.tabLine {
  background-color: rgba(132, 60, 255, 0.1);
  padding: 10px;
  margin: 5px 0;
  border-bottom: 5px dashed transparent;
  text-align: center;
  cursor: move;
  user-select: none;
}
.tabLine:hover {
  background-color: rgba(132, 60, 255, 0.3);
}
.dropTreeHover {
  border-bottom: 5px dashed #008fff;
}
