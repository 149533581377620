@import '../common.less';

.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.centerText{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  height: 100px;
  font-size: 24px;
}
.main_mask{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.main_mask2{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.main_mask::after{
  content:" ";
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  opacity: 0.86;
}
@topHeight: 75px;
.top {
  position:relative;
  width:100%;
  padding-left: @menu-width;
  height: 100px;
  bottom: auto;
  //background:@menu_bg_color;
}

.top_hide{
  .top;
  padding-left: @menu-collapsed-width;
}

.content {
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.top_menu{
  float:right;
  padding: 18px 8px 0 0;
  .icon_group{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color:white;
  }
}

.btn{
  display: table-cell;
  width: 64px;
  height: 28px;
  text-align: center;
  vertical-align: middle;
}
