.outerBox{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.header{
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.body{
  flex: 1;
  overflow: auto;
}
.copyItemsBtnBox{
  text-align: right;
  margin-top: 10px;
  padding: 0 20px;
}