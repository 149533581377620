@import "../../../../common.less";

.outerBox{

}
.showCodeBox{
  position: relative;
  height: 60vh;
  width: 100%;
}
.paramsContentHead{
  display: flex;
  justify-content: flex-end;
}
.paramsContentBody{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  :global{
    .ant-form-item{
      margin-bottom: 5px !important;
    }
  }
}
.pramsSetBox{
  position: absolute;
  top: 40px;
  left: 10px;
  right: 10px;
  bottom: 40px;
  overflow: auto;
}
.copyItemsBtnBox{
  text-align: right;
  margin-top: 10px;
  padding: 0 20px;
}
.selDataOuter{
  height: 75vh;
  position: relative;
  left: -20px;
}
.askIcon{
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 14px;
}
.desBtns{
  border-color: #843cff;
}
.eachGroupBox{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.groupName{
  font-size: 18px;
  font-weight: bold;
  color: #843cff;
}
.viewItem{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid transparent;
  transition: all 0.3s;
  font-size: 16px;
  border-radius: 5px;

  &:hover{
    color: #843cff;
    border: 1px solid #843cff;
    background-color: rgba(255,255,255,0.1);
  }
}
.viewItemSel{
  color: #843cff;
}

.viewLogo{
  width: 120px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  border: 1px solid rgba(255,255,255,0.3);
}