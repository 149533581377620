.spinBoxStyle {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.spinBoxStyle > div {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.layout {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
}
.addBtn {
  color: limegreen;
}
.addBtn:hover {
  opacity: 0.8;
}
.sortBtn {
  color: limegreen;
  color: #843cff;
}
.sortBtn:hover {
  opacity: 0.8;
}
.refreshBtn {
  color: #fff;
}
.refreshBtn:hover {
  opacity: 0.8;
}
.cardLayout {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.cardLayout :global .ant-card-head {
  min-height: 40px !important;
}
.img {
  width: 100%;
  height: calc((100vh - 400px)/3);
  background-color: #2E3244;
  transition: all 0.3s ease-in 0s;
}
