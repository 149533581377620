.outerBox{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.body{
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
}
.bodyLeft{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.controlBar{
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}
.blocklyBindContainer{
  flex: 1;
  position: relative;
}
.bodyRight{
  width: 600px;
  flex-shrink: 0;
  flex-grow: 0;
  border-left: 1px solid #aaa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.viewControlHead{
  display: flex;
  align-items: center;
  padding: 0 20px 20px;
  gap: 20px;
}
.viewContent{
  flex: 1;
  position: relative;
}
.chartOptionBox{
  position: absolute;
  right: 600px;
  top: 0;
  bottom: 0;
  width: 400px;
  background-color: #04042d;
  z-index: 999;
}
.noMockAlert{
  font-size: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}