@import "../../../../../../common.less";

.container{
  position: relative;
  overflow: hidden;
}
.noAnimate{

}
.shineAnimate{
  .animation(shine 1.5s ease-in-out infinite alternate);
}
.keyframes(shine,{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
});
.shineAnimateMore{
  .animation(shineMore 1s ease-in-out infinite alternate);
}
.keyframes(shineMore,{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
});
.moveAnimate{
  .animation(move 1.5s ease-in-out infinite);
}
.keyframes(move,{
  0% {
    left: -100%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
});
.moveAnimateLine{
  .animation(moveLine 1s ease-in-out infinite alternate);
}
.keyframes(moveLine,{
  0% {
    left: -10%;
  }
  100% {
    left: 10%;
  }
});
