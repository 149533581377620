:global .ant-btn-primary {
  box-shadow: unset;
}
:global .ant-input-outlined {
  box-shadow: unset !important;
}
:global .ant-tabs {
  height: 100%;
}
:global .ant-tabs-content {
  height: 100%;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #312f62;
  border-radius: 10px;
}
*::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  height: 5px !important;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c3b5e9;
}
body > iframe {
  z-index: -1 !important;
}
/**
* keyframes
*/
/**
* animation
*/
/**
* transform
*/
.maintheme {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.button {
  width: 144px;
  font-size: 16px;
  border-radius: 4px;
  height: 34px;
  border: none;
  cursor: pointer;
}
.buttonrestvalue {
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  border-radius: 4px;
}
.buttongetvalue {
  font-size: 14px;
  color: #1585ff;
  background: #fff;
  width: 80px;
  border-radius: 4px;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.btnText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.noText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
}
.hiddenBtn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  visibility: hidden;
}
.btnDetail {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #67cfff;
}
.btnAdd {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #009900;
}
.btnEdit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #0008ff;
}
.btnDelete {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: red;
}
.containerTitle {
  font-size: 18px;
  text-indent: 20px;
  margin-bottom: 10px;
  color: #29A6FF;
  letter-spacing: 1px;
  width: 100%;
}
.containerTitle:before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../../../../../images/cloud.png");
  background-size: 100% 100%;
  margin-right: 5px;
}
.containerTitle:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(0deg, #29a6ff 0%, #ffffff 100%);
  background: -moz-linear-gradient(left, #29A6FF, #fff);
  background: -o-linear-gradient(left, #29A6FF, #fff);
  background: -webkit-gradient(linear, 0% 100%, 100% 0%, from(#29A6FF), to(#fff));
}
.returnPage {
  float: left;
  clear: both;
  font-size: 16px;
  padding-left: 30px;
  color: #333;
  cursor: pointer;
  margin: 5px 40px;
}
.returnPage:before {
  content: "<< ";
  display: inline;
}
.returnPage:hover {
  color: #3299ff;
  padding-left: 0;
}
.returnPage:hover:before {
  content: "<<<<< ";
  display: inline;
}
.nextPage {
  float: right;
  clear: both;
  font-size: 16px;
  padding-right: 30px;
  color: #333;
  margin: 5px 40px;
  cursor: pointer;
}
.nextPage:after {
  content: " >>";
  display: inline;
}
.nextPage:hover {
  color: #3299ff;
  padding-right: 0;
}
.nextPage:hover:after {
  content: " >>>>>";
  display: inline;
}
.toolBtn {
  min-width: 120px;
  margin: 20px 10px;
}
.jumpTitle:after {
  content: ' ';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../../../../../images/outIcon01.png");
  background-size: 100% 100%;
  vertical-align: text-bottom;
  margin-left: 5px;
}
.jumpText {
  cursor: pointer;
}
.jumpText:hover {
  color: #29A6FF;
}
.stringType {
  color: green;
}
.numberType {
  color: darkorange;
}
.booleanType {
  color: blue;
}
.nullType {
  color: magenta;
}
.keyType {
  color: red;
}
.priceIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../../../../images/priceIcon.png");
  width: 18px;
  height: 14px;
  vertical-align: -12%;
  margin: 0 8px;
}
.iconBGImg {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.spinBoxStyle {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.spinBoxStyle > div {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.container {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}
.deviceContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-content: flex-start;
}
.mapShowBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
}
.img {
  width: 0;
  height: 0;
  opacity: 0;
}
.outerForRobotInfo {
  width: 100%;
  height: 80vh;
}
.mayLoadLayer {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2e303f;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
.mayLoadLayerHide {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2e303f;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  opacity: 0;
  transition: opacity 0.3s;
}
@keyframes ldio-10d8267j8wu {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-10d8267j8wu div {
  box-sizing: border-box !important;
}
.ldio-10d8267j8wu > div {
  position: absolute;
  width: 154.77px;
  height: 154.77px;
  top: 38.115px;
  left: 38.115px;
  border-radius: 50%;
  border: 6.93px solid #000;
  border-color: #5b6ee1 transparent #5b6ee1 transparent;
  animation: ldio-10d8267j8wu 1.28205128s linear infinite;
}
.ldio-10d8267j8wu > div:nth-child(2),
.ldio-10d8267j8wu > div:nth-child(4) {
  width: 136.29px;
  height: 136.29px;
  top: 47.355px;
  left: 47.355px;
  animation: ldio-10d8267j8wu 1.28205128s linear infinite reverse;
}
.ldio-10d8267j8wu > div:nth-child(2) {
  border-color: transparent #6af8ea transparent #6af8ea;
}
.ldio-10d8267j8wu > div:nth-child(3) {
  border-color: transparent;
}
.ldio-10d8267j8wu > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-10d8267j8wu > div:nth-child(3) div:before,
.ldio-10d8267j8wu > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6.93px;
  height: 6.93px;
  top: -6.93px;
  left: 66.99px;
  background: #5b6ee1;
  border-radius: 50%;
  box-shadow: 0 147.84px 0 0 #5b6ee1;
}
.ldio-10d8267j8wu > div:nth-child(3) div:after {
  left: -6.93px;
  top: 66.99px;
  box-shadow: 147.84px 0 0 0 #5b6ee1;
}
.ldio-10d8267j8wu > div:nth-child(4) {
  border-color: transparent;
}
.ldio-10d8267j8wu > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-10d8267j8wu > div:nth-child(4) div:before,
.ldio-10d8267j8wu > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6.93px;
  height: 6.93px;
  top: -6.93px;
  left: 57.75px;
  background: #6af8ea;
  border-radius: 50%;
  box-shadow: 0 129.36px 0 0 #6af8ea;
}
.ldio-10d8267j8wu > div:nth-child(4) div:after {
  left: -6.93px;
  top: 57.75px;
  box-shadow: 129.36px 0 0 0 #6af8ea;
}
.loadingio-spinner-double-ring-v0383conw4g {
  width: 231px;
  height: 231px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-10d8267j8wu {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}
.ldio-10d8267j8wu div {
  box-sizing: content-box;
}
.markerBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.markerBox:hover .markerLine {
  display: none;
}
.markerBox:hover .pointBox {
  display: none;
}
.markerNameBox {
  color: #fff;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 50px;
  min-width: 50px;
  text-align: center;
  position: relative;
  white-space: nowrap;
}
.markerNameBox .markerNum {
  display: none;
}
.markerNameBox:hover {
  text-align: left;
  padding: 8px 12px;
  border-radius: 8px;
}
.markerNameBox:hover .markerNum {
  display: block;
}
.markerLine {
  height: 45px;
  width: 2px;
}
.pointBox {
  position: relative;
  width: 0;
  height: 0;
}
.pointBox .center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 8px;
  height: 4px;
  background-color: #00FFFF;
  border-radius: 50%;
  opacity: 1;
}
.pointBox .firstRoll {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 8px;
  height: 4px;
  border: 2px solid #00FFFF;
  border-radius: 50%;
  opacity: 1;
  animation: firstRoll 2.4s ease-in-out infinite;
  /* Firefox: */
  -moz-animation: firstRoll 2.4s ease-in-out infinite;
  /* Safari 和 Chrome: */
  -webkit-animation: firstRoll 2.4s ease-in-out infinite;
  /* Opera: */
  -o-animation: firstRoll 2.4s ease-in-out infinite;
}
.pointBox .secondRoll {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 8px;
  height: 4px;
  border: 2px solid #00FFFF;
  border-radius: 50%;
  opacity: 1;
  animation: firstRoll 2.4s ease-in-out infinite;
  /* Firefox: */
  -moz-animation: firstRoll 2.4s ease-in-out infinite;
  /* Safari 和 Chrome: */
  -webkit-animation: firstRoll 2.4s ease-in-out infinite;
  /* Opera: */
  -o-animation: firstRoll 2.4s ease-in-out infinite;
  animation: firstRoll 2.4s ease-in-out infinite 1.1s;
  /* Firefox: */
  -moz-animation: firstRoll 2.4s ease-in-out infinite 1.1s;
  /* Safari 和 Chrome: */
  -webkit-animation: firstRoll 2.4s ease-in-out infinite 1.1s;
  /* Opera: */
  -o-animation: firstRoll 2.4s ease-in-out infinite 1.1s;
}
@-moz-keyframes firstRoll {
  0% {
    opacity: 1;
    width: 8px;
    height: 4px;
    border-width: 2px;
  }
  100% {
    opacity: 0;
    width: 36px;
    height: 18px;
    border-width: 1px;
  }
}
@-o-keyframes firstRoll {
  0% {
    opacity: 1;
    width: 8px;
    height: 4px;
    border-width: 2px;
  }
  100% {
    opacity: 0;
    width: 36px;
    height: 18px;
    border-width: 1px;
  }
}
@-webkit-keyframes firstRoll {
  0% {
    opacity: 1;
    width: 8px;
    height: 4px;
    border-width: 2px;
  }
  100% {
    opacity: 0;
    width: 36px;
    height: 18px;
    border-width: 1px;
  }
}
@keyframes firstRoll {
  0% {
    opacity: 1;
    width: 8px;
    height: 4px;
    border-width: 2px;
  }
  100% {
    opacity: 0;
    width: 36px;
    height: 18px;
    border-width: 1px;
  }
}
.legendBox {
  z-index: 9;
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 2px;
  background: rgba(0, 0, 0, 0.35);
  padding: 10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px 0px;
}
.legendItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}
.colorItem {
  width: 16px;
  height: 10px;
  border-radius: 2px;
}
.colorTitle {
  font-size: 12px;
  color: #fff;
  word-break: keep-all;
  white-space: nowrap;
}
