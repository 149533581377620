.container {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}
.deviceContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-content: flex-start;
}
.pagContainer {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
}
.mapShowBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
}
.img {
  width: 0;
  height: 0;
  opacity: 0;
}
.outerForRobotInfo {
  width: 100%;
  height: 80vh;
}
