.itemBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.itemValue{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}