:global .ant-btn-primary {
  box-shadow: unset;
}
:global .ant-input-outlined {
  box-shadow: unset !important;
}
:global .ant-tabs {
  height: 100%;
}
:global .ant-tabs-content {
  height: 100%;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #312f62;
  border-radius: 10px;
}
*::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  height: 5px !important;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c3b5e9;
}
body > iframe {
  z-index: -1 !important;
}
/**
* keyframes
*/
/**
* animation
*/
/**
* transform
*/
.maintheme {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.button {
  width: 144px;
  font-size: 16px;
  border-radius: 4px;
  height: 34px;
  border: none;
  cursor: pointer;
}
.buttonrestvalue {
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  border-radius: 4px;
}
.buttongetvalue {
  font-size: 14px;
  color: #1585ff;
  background: #fff;
  width: 80px;
  border-radius: 4px;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.btnText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.noText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
}
.hiddenBtn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  visibility: hidden;
}
.btnDetail {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #67cfff;
}
.btnAdd {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #009900;
}
.btnEdit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #0008ff;
}
.btnDelete {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: red;
}
.containerTitle {
  font-size: 18px;
  text-indent: 20px;
  margin-bottom: 10px;
  color: #29A6FF;
  letter-spacing: 1px;
  width: 100%;
}
.containerTitle:before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../../images/cloud.png");
  background-size: 100% 100%;
  margin-right: 5px;
}
.containerTitle:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(0deg, #29a6ff 0%, #ffffff 100%);
  background: -moz-linear-gradient(left, #29A6FF, #fff);
  background: -o-linear-gradient(left, #29A6FF, #fff);
  background: -webkit-gradient(linear, 0% 100%, 100% 0%, from(#29A6FF), to(#fff));
}
.returnPage {
  float: left;
  clear: both;
  font-size: 16px;
  padding-left: 30px;
  color: #333;
  cursor: pointer;
  margin: 5px 40px;
}
.returnPage:before {
  content: "<< ";
  display: inline;
}
.returnPage:hover {
  color: #3299ff;
  padding-left: 0;
}
.returnPage:hover:before {
  content: "<<<<< ";
  display: inline;
}
.nextPage {
  float: right;
  clear: both;
  font-size: 16px;
  padding-right: 30px;
  color: #333;
  margin: 5px 40px;
  cursor: pointer;
}
.nextPage:after {
  content: " >>";
  display: inline;
}
.nextPage:hover {
  color: #3299ff;
  padding-right: 0;
}
.nextPage:hover:after {
  content: " >>>>>";
  display: inline;
}
.toolBtn {
  min-width: 120px;
  margin: 20px 10px;
}
.jumpTitle:after {
  content: ' ';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../../images/outIcon01.png");
  background-size: 100% 100%;
  vertical-align: text-bottom;
  margin-left: 5px;
}
.jumpText {
  cursor: pointer;
}
.jumpText:hover {
  color: #29A6FF;
}
.stringType {
  color: green;
}
.numberType {
  color: darkorange;
}
.booleanType {
  color: blue;
}
.nullType {
  color: magenta;
}
.keyType {
  color: red;
}
.priceIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../images/priceIcon.png");
  width: 18px;
  height: 14px;
  vertical-align: -12%;
  margin: 0 8px;
}
.iconBGImg {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.spinBoxStyle {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.spinBoxStyle > div {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.outBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5D5DCB;
}
.outBox div,
.outBox span {
  color: #fff;
}
.headBox {
  position: absolute;
  height: 50px;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.headBox .halfTop {
  width: 50%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.headBox .halfTopUnSel {
  background: #4C4CB8;
  color: rgba(255, 255, 255, 0.5);
}
.argBox {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 40px;
  padding: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.argBox div,
.argBox span {
  color: #333;
}
.editItem {
  position: relative;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
}
.editItem .editLabel {
  font-size: 14px;
  margin-right: 8px;
  width: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.editItem .editValue {
  display: flex;
  flex: 1;
  min-width: 0;
}
.bottomBox {
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: #5D5DCB;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  justify-content: space-between;
}
.btnBox {
  display: flex;
  justify-content: space-evenly;
}
.box {
  position: relative;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}
.item {
  text-align: left;
  margin: 2px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item div,
.item span {
  color: #fff;
}
.item02 {
  text-align: left;
  word-break: break-all;
}
.item02 div,
.item02 span {
  color: #fff;
}
.label {
  display: inline;
  font-weight: bold;
  font-size: 14px;
}
.value {
  display: inline;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
.addBtn {
  width: 30%;
  align-self: center;
  justify-self: center;
}
.delBtn {
  font-size: 20px;
  transform: translate(0, -50%);
}
.delBtn:hover {
  color: #e9071a;
  cursor: pointer;
}
.emptyBox {
  margin-top: 50px;
  text-align: center;
  font-size: 16px;
}
.dividerBox {
  border-bottom: 1px solid #999;
  margin: 5px 0;
}
.empty {
  padding: 10px;
}
.left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  background: #5252BD;
  border-radius: 2px;
  padding: 12px 16px;
  min-width: 0;
}
.right {
  margin-left: 8px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #5252BD;
  border-radius: 2px;
  flex-direction: column;
}
.editIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url("../../../images/argManager/editIcon.png");
}
.editIcon:hover {
  opacity: 0.75;
}
.deleteIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url("../../../images/argManager/editIcon.png");
  background-image: url("../../../images/argManager/deleteIcon.png");
}
.deleteIcon:hover {
  opacity: 0.75;
}
.matchStyle {
  color: white;
  background-color: red;
}
.selectedIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: -12%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/selIcon.png");
}
.unSelIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: -12%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/selIcon.png");
  background-image: url("../../../images/argManager/unselIcon.png");
}
.plusBtnIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: -8%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/plusBtnIcon.png");
}
.confirmBtnIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: -8%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/plusBtnIcon.png");
  width: 18px;
  height: 12px;
  background-image: url("../../../images/argManager/confirmBtnIcon.png");
}
.saveBtnIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: -8%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/plusBtnIcon.png");
  background-image: url("../../../images/argManager/saveBtnIcon.png");
}
.closeBtnIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  vertical-align: -8%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/plusBtnIcon.png");
  width: 11px;
  height: 12px;
  background-image: url("../../../images/argManager/closeBtnIcon.png");
}
.addBtnAlone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  cursor: pointer;
}
.addBtnAlone:hover {
  opacity: 0.75;
}
.twoBtns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.twoBtns .halfBtn {
  width: 45%;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}
.twoBtns .halfBtn:hover {
  opacity: 0.75;
}
.line {
  width: 1px;
  height: 16px;
  background: rgba(0, 0, 0, 0.2);
}
.diyInput {
  border: 1px solid rgba(255, 255, 255, 0.25);
  height: 36px;
}
.diyInput:hover {
  border: 1px solid rgba(255, 255, 255, 0.35);
}
.diyInput:focus {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.diyInput02 {
  border: none;
  height: 28px;
  background-color: transparent;
}
.diyInput02:hover {
  background-color: transparent;
}
.diyInput02:focus {
  background-color: transparent;
}
.diyInputNumber {
  width: 100%;
}
.diySelect {
  width: 100%;
}
.normal {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
}
.addIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-image: url("../../../images/argManager/plusIcon.png");
  margin-right: 14px;
  background-color: limegreen;
  cursor: pointer;
}
.addIconDis {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-image: url("../../../images/argManager/plusIcon.png");
  margin-right: 14px;
  background-color: limegreen;
  cursor: pointer;
  opacity: 0.5;
  background-color: #666;
  cursor: not-allowed;
}
.addInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addSelItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addSelItem .al {
  width: 90%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.deleteSelIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-image: url("../../../images/argManager/plusIcon.png");
  margin-right: 14px;
  background-color: limegreen;
  cursor: pointer;
  margin: 0 6px;
  background-image: url("../../../images/argManager/minusIcon.png");
}
