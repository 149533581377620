@import "../../../common.less";
.outBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5D5DCB;

  & div, & span{
    color: #fff;
  }
}
.headBox{
  position: absolute;
  height: 50px;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  font-size: 16px;
  font-weight: bold;

  .halfTop{
    width: 50%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .halfTopUnSel{
    background: #4C4CB8;
    color: rgba(255, 255, 255, 0.5);
  }
}
.argBox{
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 40px;
  padding: 12px;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: #fff;
  & div, & span{
    color: #333;
  }
}
.editItem{
  position: relative;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  .editLabel{
    font-size: 14px;
    margin-right: 8px;
    width: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .editValue{
    display: flex;
    flex: 1;
    min-width: 0;
  }
}
.bottomBox{
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: #5D5DCB;
  border-top: 1px solid rgba(0,0,0,0.2);
  justify-content: space-between;
}
.btnBox{
  display: flex;
  justify-content: space-evenly;
}
.box{
  position: relative;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}
.item{
  text-align: left;
  margin: 2px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & div, & span{
    color: #fff;
  }
}
.item02{
  text-align: left;
  word-break: break-all;

  & div, & span{
    color: #fff;
  }
}
.label{
  display: inline;
  font-weight: bold;
  font-size: 14px;
}
.value{
  display: inline;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
.addBtn{
  width: 30%;
  align-self: center;
  justify-self: center;
}
.delBtn{
  font-size: 20px;
  transform: translate(0,-50%);

  &:hover{
    color:#e9071a;
    cursor: pointer;
  }
}
.emptyBox{
  margin-top: 50px;
  text-align: center;
  font-size: 16px;
}
.dividerBox{
  border-bottom: 1px solid #999;
  margin: 5px 0;
}
.empty{
  padding: 10px;
}
.left{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  background: #5252BD;
  border-radius: 2px;
  padding: 12px 16px;
  min-width: 0;
}
.right{
  margin-left: 8px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #5252BD;
  border-radius: 2px;
  flex-direction: column;
}
.editIcon{
  .iconBGImg;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url("../../../images/argManager/editIcon.png");

  &:hover{
    opacity: 0.75;
  }
}
.deleteIcon{
  .editIcon;
  background-image: url("../../../images/argManager/deleteIcon.png");
}
.matchStyle {
  color: white;
  background-color: red;
}
.selectedIcon{
  .iconBGImg;
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: -12%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/selIcon.png");
}
.unSelIcon{
  .selectedIcon;
  background-image: url("../../../images/argManager/unselIcon.png");
}
.plusBtnIcon{
  .iconBGImg;
  width: 14px;
  height: 14px;
  vertical-align: -8%;
  margin-right: 8px;
  background-image: url("../../../images/argManager/plusBtnIcon.png");
}
.confirmBtnIcon{
  .plusBtnIcon;
  width: 18px;
  height: 12px;
  background-image: url("../../../images/argManager/confirmBtnIcon.png");
}
.saveBtnIcon{
  .plusBtnIcon;
  background-image: url("../../../images/argManager/saveBtnIcon.png");
}
.closeBtnIcon{
  .plusBtnIcon;
  width: 11px;
  height: 12px;
  background-image: url("../../../images/argManager/closeBtnIcon.png");
}
.addBtnAlone{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  cursor: pointer;

  &:hover{
    opacity: 0.75;
  }
}
.twoBtns{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .halfBtn{
    width: 45%;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    &:hover{
      opacity: 0.75;
    }
  }
}
.line{
  width: 1px;
  height: 16px;
  background: rgba(0, 0, 0, 0.2);
}
.diyInput{
  border: 1px solid rgba(255, 255, 255, 0.25);
  height: 36px;
  //background-color: #5757C5;

  &:hover{
    border: 1px solid rgba(255, 255, 255, 0.35);
  }
  &:focus{
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
}
.diyInput02{
  border: none;
  height: 28px;
  background-color: transparent;

  &:hover{
    background-color: transparent;
  }
  &:focus{
    background-color: transparent;
  }
}
.diyInputNumber{
  width: 100%;

  //:global{
  //  .ant-input-number{
  //    border: none !important;
  //  }
  //  .ant-input-number-input{
  //    border: 1px solid rgba(255, 255, 255, 0.25) !important;
  //    height: 36px;
  //    background-color: #5757C5 !important;
  //    &:hover{
  //      border: 1px solid rgba(255, 255, 255, 0.35) !important;
  //    }
  //    &:focus{
  //      border: 1px solid rgba(255, 255, 255, 0.5) !important;
  //    }
  //  }
  //}

}
.diySelect{
  width: 100%;
  //:global{
  //  .ant-select-selector{
  //    background-color: #5757C5 !important;
  //    border: 1px solid rgba(255, 255, 255, 0.25) !important;
  //  }
  //  .ant-select-multiple .ant-select-selection-item{
  //    border: 1px solid rgba(255, 255, 255, 0.5) !important;
  //  }
  //}
}
.dropDownClass{
  //background-color: #6969E2;
}
.diyRadio{
  //:global{
  //  .ant-radio-inner{
  //    border: 1px solid rgba(255, 255, 255, 0.9) !important;
  //  }
  //  .ant-radio-inner::after{
  //    background-color: #fff !important;
  //  }
  //}
}
.normal{
  height: 36px;
  font-size: 14px;
  line-height: 36px;
}
.addIcon{
  .iconBGImg;
  width: 20px;
  height: 20px;
  background-image: url("../../../images/argManager/plusIcon.png");
  margin-right: 14px;
  background-color: limegreen;

  cursor: pointer;
}
.addIconDis{
  .addIcon;
  opacity: 0.5;
  background-color: #666;
  cursor: not-allowed;
}
.addInput{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addSelItem{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .al{
    width: 90%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.deleteSelIcon{
  .addIcon;
  margin: 0 6px;
  background-image: url("../../../images/argManager/minusIcon.png");
}