@import '../../ContentView.less';

.outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.tabsBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.tabPaneBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.progressBox {
  width: 200px;
  height: 30px;
  border-radius: 30px;
  background-color: #e0e0e0;
  overflow: hidden;
}
.progress {
  width: 10px;
  height: inherit;
  background-color: #1078d7;
}

.hangInTheAir {
  color: #000 !important;
}
