:global .ant-btn-primary {
  box-shadow: unset;
}
:global .ant-input-outlined {
  box-shadow: unset !important;
}
:global .ant-tabs {
  height: 100%;
}
:global .ant-tabs-content {
  height: 100%;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #312f62;
  border-radius: 10px;
}
*::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  height: 5px !important;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c3b5e9;
}
body > iframe {
  z-index: -1 !important;
}
/**
* keyframes
*/
/**
* animation
*/
/**
* transform
*/
.maintheme {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.button {
  width: 144px;
  font-size: 16px;
  border-radius: 4px;
  height: 34px;
  border: none;
  cursor: pointer;
}
.buttonrestvalue {
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  border-radius: 4px;
}
.buttongetvalue {
  font-size: 14px;
  color: #1585ff;
  background: #fff;
  width: 80px;
  border-radius: 4px;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.btnText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.noText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
}
.hiddenBtn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  visibility: hidden;
}
.btnDetail {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #67cfff;
}
.btnAdd {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #009900;
}
.btnEdit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #0008ff;
}
.btnDelete {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: red;
}
.containerTitle {
  font-size: 18px;
  text-indent: 20px;
  margin-bottom: 10px;
  color: #29A6FF;
  letter-spacing: 1px;
  width: 100%;
}
.containerTitle:before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../../../../../images/cloud.png");
  background-size: 100% 100%;
  margin-right: 5px;
}
.containerTitle:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(0deg, #29a6ff 0%, #ffffff 100%);
  background: -moz-linear-gradient(left, #29A6FF, #fff);
  background: -o-linear-gradient(left, #29A6FF, #fff);
  background: -webkit-gradient(linear, 0% 100%, 100% 0%, from(#29A6FF), to(#fff));
}
.returnPage {
  float: left;
  clear: both;
  font-size: 16px;
  padding-left: 30px;
  color: #333;
  cursor: pointer;
  margin: 5px 40px;
}
.returnPage:before {
  content: "<< ";
  display: inline;
}
.returnPage:hover {
  color: #3299ff;
  padding-left: 0;
}
.returnPage:hover:before {
  content: "<<<<< ";
  display: inline;
}
.nextPage {
  float: right;
  clear: both;
  font-size: 16px;
  padding-right: 30px;
  color: #333;
  margin: 5px 40px;
  cursor: pointer;
}
.nextPage:after {
  content: " >>";
  display: inline;
}
.nextPage:hover {
  color: #3299ff;
  padding-right: 0;
}
.nextPage:hover:after {
  content: " >>>>>";
  display: inline;
}
.toolBtn {
  min-width: 120px;
  margin: 20px 10px;
}
.jumpTitle:after {
  content: ' ';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../../../../../images/outIcon01.png");
  background-size: 100% 100%;
  vertical-align: text-bottom;
  margin-left: 5px;
}
.jumpText {
  cursor: pointer;
}
.jumpText:hover {
  color: #29A6FF;
}
.stringType {
  color: green;
}
.numberType {
  color: darkorange;
}
.booleanType {
  color: blue;
}
.nullType {
  color: magenta;
}
.keyType {
  color: red;
}
.priceIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../../../../images/priceIcon.png");
  width: 18px;
  height: 14px;
  vertical-align: -12%;
  margin: 0 8px;
}
.iconBGImg {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.spinBoxStyle {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.spinBoxStyle > div {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.contentOuter {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.contentBgBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  overflow: hidden;
  z-index: -1;
}
.contentTitleBox {
  z-index: 1;
}
.contentPaddingBox {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex: 1;
  order: 5;
}
.realContentBox {
  flex: 1;
  display: flex;
  position: relative;
}
.warnMsgBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #843CFF;
}
.errorMsg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #843CFF;
  color: darkorange;
}
.videoBgStyle {
  width: 100%;
  height: 100%;
}
.iconSetImg {
  display: inline-block;
  background-size: 100% 100%;
}
.seeDataBtn {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  opacity: 0.3;
  z-index: 20;
  font-size: 16px;
}
.seeDataBtn:hover {
  opacity: 1;
}
.paramsSelLayer {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 40px;
  left: 0;
  padding: 10px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 10px;
  background-color: #111;
}
.hoverShowDesc:hover .descIcon {
  opacity: 1;
}
.descIcon {
  position: absolute;
  z-index: 15;
  opacity: 0;
  transition: all 0.3s;
}
.coverContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.controlBar {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row-reverse;
}
.chartBox {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
}
