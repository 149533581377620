@import '../../ContentView.less';

.outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.controlBar {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row-reverse;
}
.chartBox {
  flex: 1;
  position: relative;
}
.quantityBulk {
  // width: calc((100% + 16px) / 3 - 16px);
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(246, 198, 249, 0.4) 0%,
    rgba(164, 164, 218, 0.4) 100%
  );
  //阴影
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  margin-bottom: 16px;
}
.quantityBulkTwo {
  .quantityBulk;
  background: linear-gradient(
    135deg,
    rgba(246, 191, 191, 0.4) 0%,
    rgba(252, 231, 202, 0.4) 100%
  );
  //阴影
}
.taskCountAll {
  :global {
    .ant-statistic-title {
      margin-bottom: 0;
    }
  }
}
.quantityBulkThree {
  .quantityBulk;
  background: linear-gradient(
    135deg,
    rgba(191, 246, 249, 0.4) 0%,
    rgba(218, 164, 164, 0.4) 100%
  );
  //阴影
}
