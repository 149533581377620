@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: stretch;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(246, 198, 249, 0.4) 0%,
    rgba(164, 164, 218, 0.4) 100%
  );
}

.inner {
  padding: 24px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.barBox{
  flex: 1;
  height: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ffffff33;
}

.onlineBar{
  background-color: skyblue;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.offlineBar{
  .onlineBar;
  background-color: #1465b7;
  justify-content: flex-end;
}

.workBar {
  .onlineBar;
  background-color: #1465b7;
  justify-content: flex-start;
}

.idleBar {
  .onlineBar;
  background-color: orange;
  justify-content: flex-end;
}