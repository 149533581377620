.outerBox {
  width: 100%;
  height: 100%;
}
.outerBox :global .ant-tabs-content {
  height: 100%;
}
.tabPaneBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2b2a42;
}
