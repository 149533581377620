@import "../../../../common.less";
.colorShowBox{
  width: 35px;
  height: 18px;
  border: 1px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  color: #aaa;
}
.spaceBox{
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
.colorSetBtn{
  .iconBGImg;
  width: 18px;
  height: 18px;
  background-image: url("../../../../images/dataSet/colorSet.png");
  cursor: pointer;
}
.tabContainer{
  position: relative;
  width: 270px;
  height: 420px;
  pointer-events: auto;
}
.sketchBox{
  & input{
    color: #000;
  }
}

.reTitle{
  font-size: 12px;
  color: #333;
}
.reContent{
  display: flex;
  flex-wrap: wrap;
  gap: 5px 0px;
  margin-bottom: 10px;
}
.reContainer{
  max-height: 350px;
  overflow: auto;
}
.reColorItem{
  width: 30px;
  height: 20px;
  cursor: pointer;
}