.outerBox{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.headMsgBox{
  display: flex;
  align-items: center;
  justify-content: center;
}
.barBorderContainer{
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
  border-style: solid;
}
.barContainer{
  flex: 1;
  position: relative;
  overflow: hidden;
}
.innerBar{
  position: absolute;
  top: 0;
  bottom: 0;
}
.footerMsgBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}