.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  overflow: auto;
}
.code {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.label {
  display: inline-block;
  color: #ebc725;
  min-width: 80px;
  text-align: right;
  padding-right: 5px;
}
.tool {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 20px;
  padding: 5px;
  color: #eeeeee;
}
.tool:hover {
  color: #48b5ff;
}
