@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.controlBar{
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row-reverse;
}

.chartBox {
  position: relative;
  flex: 1;
  display: flex;
  // align-items: stretch;
  width: 100%;
  height: 100%;
}
