@import "../../../../common.less";

.dashboardSetBox{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  :global{
    .ant-form-item{
      margin-bottom: 5px !important;
    }
  }
}
.dashboardSetHead{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.dashboardSetBody{
  flex: 1;
}
.notMyValueLabel{
  opacity: 0.5;
}
.resetBtn{
  color: darkorange;
  margin-right: 5px;
  cursor: pointer;
}
.dynamicSetBottomBar{
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
}

.file_uploader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.tabPaneRightBox{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  overflow: overlay;
  overflow-x: hidden;
}
.copyItemsBtnBox{
  text-align: right;
  margin-bottom: 10px;
  padding: 0 20px;
}
.svgContainer{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 330px;
  max-height: 400px;
  overflow: auto;
  overflow: overlay;
}
.svgShowBox{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.svgContent{
  width: 150px;
  height: 100px;
}
.navDemoSetBody{
  flex: 1;
  display: flex;
  align-items: stretch;
}
.navDemoSetBodyLeft{
  position: relative;
  width: 600px;
}
.navDemoSetFooter{
  height: 400px;
  margin-top: 10px;
  position: relative;
  background-color: #282b3a;
  background-image: linear-gradient(
          90deg,
          rgba(33, 233, 252, 0.15) 1%,
          rgba(0, 0, 0, 0) 5%
  ),
  linear-gradient(rgba(33, 233, 252, 0.15) 1%, rgba(0, 0, 0, 0) 5%);
  background-size: 28px 28px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.swiperTitleBox{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}
.swiperContent{
  flex: 1;
  order: 5;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255, 0.1);
}
