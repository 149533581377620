@import "../../ContentView.less";

.outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.controlBar {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
}

.card {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg,
      rgba(246, 198, 249, 0.4) 0%,
      rgba(164, 164, 218, 0.4) 100%);
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.title {
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
}

.charBox {
  width: '50%';
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.timeSuffix {
  font-size: 14px;
}