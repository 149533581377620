:global .ant-btn-primary {
  box-shadow: unset;
}
:global .ant-input-outlined {
  box-shadow: unset !important;
}
:global .ant-tabs {
  height: 100%;
}
:global .ant-tabs-content {
  height: 100%;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #312f62;
  border-radius: 10px;
}
*::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  height: 5px !important;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c3b5e9;
}
body > iframe {
  z-index: -1 !important;
}
/**
* keyframes
*/
/**
* animation
*/
/**
* transform
*/
.maintheme {
  position: absolute;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.button {
  width: 144px;
  font-size: 16px;
  border-radius: 4px;
  height: 34px;
  border: none;
  cursor: pointer;
}
.buttonrestvalue {
  font-size: 14px;
  width: 80px;
  letter-spacing: 0;
  border-radius: 4px;
}
.buttongetvalue {
  font-size: 14px;
  color: #1585ff;
  background: #fff;
  width: 80px;
  border-radius: 4px;
}
.noSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}
.btnText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.noText {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  width: 24px;
}
.hiddenBtn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  visibility: hidden;
}
.btnDetail {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #67cfff;
}
.btnAdd {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #009900;
}
.btnEdit {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: #0008ff;
}
.btnDelete {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  color: red;
}
.containerTitle {
  font-size: 18px;
  text-indent: 20px;
  margin-bottom: 10px;
  color: #29A6FF;
  letter-spacing: 1px;
  width: 100%;
}
.containerTitle:before {
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url("../../../images/cloud.png");
  background-size: 100% 100%;
  margin-right: 5px;
}
.containerTitle:after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(0deg, #29a6ff 0%, #ffffff 100%);
  background: -moz-linear-gradient(left, #29A6FF, #fff);
  background: -o-linear-gradient(left, #29A6FF, #fff);
  background: -webkit-gradient(linear, 0% 100%, 100% 0%, from(#29A6FF), to(#fff));
}
.returnPage {
  float: left;
  clear: both;
  font-size: 16px;
  padding-left: 30px;
  color: #333;
  cursor: pointer;
  margin: 5px 40px;
}
.returnPage:before {
  content: "<< ";
  display: inline;
}
.returnPage:hover {
  color: #3299ff;
  padding-left: 0;
}
.returnPage:hover:before {
  content: "<<<<< ";
  display: inline;
}
.nextPage {
  float: right;
  clear: both;
  font-size: 16px;
  padding-right: 30px;
  color: #333;
  margin: 5px 40px;
  cursor: pointer;
}
.nextPage:after {
  content: " >>";
  display: inline;
}
.nextPage:hover {
  color: #3299ff;
  padding-right: 0;
}
.nextPage:hover:after {
  content: " >>>>>";
  display: inline;
}
.toolBtn {
  min-width: 120px;
  margin: 20px 10px;
}
.jumpTitle:after {
  content: ' ';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../../images/outIcon01.png");
  background-size: 100% 100%;
  vertical-align: text-bottom;
  margin-left: 5px;
}
.jumpText {
  cursor: pointer;
}
.jumpText:hover {
  color: #29A6FF;
}
.stringType {
  color: green;
}
.numberType {
  color: darkorange;
}
.booleanType {
  color: blue;
}
.nullType {
  color: magenta;
}
.keyType {
  color: red;
}
.priceIcon {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../images/priceIcon.png");
  width: 18px;
  height: 14px;
  vertical-align: -12%;
  margin: 0 8px;
}
.iconBGImg {
  display: inline-block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.spinBoxStyle {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.spinBoxStyle > div {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mainPage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
.outerLayoutBox {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #19193c;
}
.outerLayoutBox :global .ant-drawer .ant-collapse-content {
  background-color: unset !important;
}
.outerLayoutBox :global .ant-collapse-content > .ant-collapse-content-box {
  padding: 4px;
}
.outerLayoutBox :global .swiper-button-next {
  opacity: 0.3;
}
.outerLayoutBox :global .swiper-button-next:hover {
  opacity: 1;
}
.outerLayoutBox :global .swiper-button-prev {
  opacity: 0.3;
}
.outerLayoutBox :global .swiper-button-prev:hover {
  opacity: 1;
}
.outerLayoutBox :global .swiper-scrollbar-drag {
  opacity: 0.3;
}
.outerLayoutBox :global .swiper-scrollbar-drag:hover {
  opacity: 1;
}
.outerLayoutBox :global .swiper-pagination-current {
  font-family: inherit;
}
.outerLayoutBox :global .swiper-pagination-total {
  font-family: inherit;
}
.mainHeadBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1f2333;
  font-size: 14px;
}
.thumbsBox {
  position: relative;
}
.mainBodyBox {
  flex: 1;
  position: relative;
  order: 5;
}
.thumbsItem {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #67cfff;
}
.thumbsItemAct {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #67cfff;
  color: red;
  background-color: #88e996;
}
.tabPaneContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.swiperPaneContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.leftControlBar {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #2e3244;
}
.dashboardContent {
  flex: 1;
  position: relative;
  overflow: overlay;
  overflow: auto;
  z-index: 1;
}
.gridBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dashboardContentHasBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, rgba(33, 233, 252, 0.15) 1%, rgba(0, 0, 0, 0) 5%), linear-gradient(rgba(33, 233, 252, 0.15) 1%, rgba(0, 0, 0, 0) 5%);
  background-size: 28px 28px;
}
.dashboardContentHasBgMobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, rgba(248, 163, 85, 0.25) 1%, rgba(0, 0, 0, 0) 5%), linear-gradient(rgba(248, 163, 85, 0.25) 1%, rgba(0, 0, 0, 0) 5%);
  background-size: 28px 28px;
}
.resizableBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: overlay;
  overflow: auto;
  z-index: 1;
  border: 1px solid transparent;
}
.resizableBox:focus {
  border: 1px dashed rgba(132, 30, 255, 0.65);
}
.disResizableBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;
  border: 1px solid transparent;
}
.disResizableBox:focus {
  border: 1px dashed rgba(132, 30, 255, 0.65);
}
.leftControlBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  margin: 4px 0;
  user-select: none;
}
.leftControlBtn:hover span,
.leftControlBtn:hover div {
  color: #843CFF;
}
.contentBox {
  border: 2px dashed #1e90ff3f;
}
.contentBoxForDy {
  border: 2px dashed #ff8c003f;
}
.selContentBox {
  border: 2px dashed limegreen;
}
.connectContentBox {
  border: 2px solid #843CFF;
}
.contentSetBarMark {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}
.contentSetBar {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.contentSetBtn {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.contentSetBtn:hover {
  color: #843CFF;
}
.dynamicContentSetBox {
  width: 300px;
  background-color: #2e3244;
}
.rightSideTabPaneBox {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  overflow: auto;
  overflow: overlay;
  overflow-x: hidden;
}
.rightSideTabPaneBox ::-webkit-scrollbar {
  z-index: 999;
}
.tabSelName {
  font-size: 18px;
  line-height: 18px;
  padding: 0 5px;
}
.tabSelName div,
.tabSelName span {
  color: #843CFF;
}
.tabNameNormal {
  padding: 0 5px;
  font-size: 14px;
  line-height: 12px;
}
.thumbsContainerS :global .swiper-wrapper {
  justify-content: flex-start;
}
.thumbsContainerC :global .swiper-wrapper {
  justify-content: center;
}
.thumbsContainerE :global .swiper-wrapper {
  justify-content: flex-end;
}
.swiperTitleBox {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
}
.sizeMsg {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 5px;
  font-size: 16px;
  z-index: 99;
}
.leftViewSelBox {
  padding: 10px;
  width: 600px;
  max-height: 60vh;
  overflow: auto;
}
.parameterUidShow {
  position: absolute;
  top: -20px;
  left: 0;
  opacity: 0.5;
}
.file_uploader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
