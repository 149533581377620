.mainPage{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header{
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid dodgerblue;
}
.headTitle{
  font-weight: bold;
  color: dodgerblue;
  font-size: 20px;
}
.headItem{
  display: inline-block;
  margin-left: 20px;
}
.body{
  flex: 1;
  display: flex;
  overflow: auto;
  padding: 20px;
  flex-direction: column;
}
.deviceTotalBox{
  display: flex;
  gap: 80px;
}
.totalItemBox{

}
.tibTitle{
  font-size: 16px;
}
.tibValue{
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.onlineExtraBox{
  font-size: 12px;
  font-weight: normal;
  display: inline-flex;
  margin-left: 20px;
}
.oebItem{
  margin-right: 20px;
}
.chartOuterBox{
  border: 1px solid rgba(30,144,255,0.5);
  margin: 10px 0;
  padding: 10px;
  border-radius: 6px;
  background-color: rgba(30,144,255,0.05);
  //box-shadow: 0px 6px 8px 0px rgba(30,144,255,0.2);
}
.chartHeadBox{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.chTitle{
  font-size: 16px;
  color: #1e90ff;
  gap: 10px;
  display: flex;
  align-items: center;
}
.chDesc{
  color: #bbb;
}
.eyeIcon{
  font-size: 16px;
  cursor: pointer;
}
.chartBodyBox{
  overflow: hidden;
  width: 100%;
  height: 400px;
}
.chartContainer{
  position: relative;
  width: 100%;
  height: 100%;
}
.extraMsgBox{
  position: absolute;
  top: 0px;
  right: 30px;
}
.boxOneExtra{
  .extraMsgBox;
  color: dodgerblue;
}
.boxTwoExtra{
  .extraMsgBox;
  color: red;
}
.boxThreeExtra{
  .extraMsgBox;
  color: limegreen;
}
.boxFiveExtra{
  .extraMsgBox;
  top: 20px;
}
.chartGroupBox{
  display: flex;
  justify-content: space-between;
  gap: 20px;
}