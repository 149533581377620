@import "../../ContentView.less";

.outer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.controlBar{
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row-reverse;
}

.chartBox {
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}

.onlineBar {
  flex: 1;
  flex-wrap: nowrap;
  background-color: blue;
  border-radius: 24px;
  min-width: 128px;
  height: 32px;
}

.workBar {
  flex-wrap: nowrap;
  background-color: orange;
  min-width: 50px;
  margin: 1px;;
  border-radius: 24px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Horizontal offset, Vertical offset, Blur radius, Shadow color */
  // transition: box-shadow 0.3s ease;
}

.divider {
  background-color: blue;
  width: 20px;
  height: 2px;
}

.worktimelabel {
  .worktime
}

.worktime {
  height: 30px;
  font-size: 12px;
  color: #fff;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.onlinetime {
  font-size: 12px;
}