@import "../../../../../../common.less";

.chartBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chartDom{
  width: 100%;
  height: 100%;
}

.tabLine{
  display: flex;
  align-items: center;
  background-color: rgba(132, 60, 255, 0.1);
  padding: 0px;
  margin: 5px 0;
  border-bottom: 4px dashed transparent;
  border-top: 4px dashed transparent;

  text-align: center;

  cursor: move;
  .noSelect;

  &:hover{
    background-color: rgba(132, 60, 255, 0.3);
  }
}
.dropTreeHover{
  border-bottom: 4px dashed #008fff;
}
.errorMsg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: darkorange;
  text-align: center;
}
.totalMsg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
}