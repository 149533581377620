.show {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

}

.btnBox{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  box-sizing: border-box;
}
.btn{
  margin: 0;
}