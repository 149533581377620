@import "../../../../common.less";

.contentOuter{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.contentBgBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  overflow: hidden;
  z-index: -1;
}
.contentTitleBox{
  z-index: 1;
}
.contentPaddingBox{
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex: 1;
  order: 5;
}
.realContentBox{
  flex: 1;
  display: flex;
  position: relative;

  //& div,& span{
  //  color: inherit;
  //  font-size: inherit;
  //  font-weight: inherit;
  //  font-style: inherit;
  //  word-spacing: inherit;
  //}
}
.warnMsgBox{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #843CFF;
}
.errorMsg{
  .warnMsgBox;
  color: darkorange;
}
.videoBgStyle{
  width: 100%;
  height: 100%;
}
.iconSetImg{
  display: inline-block;
  background-size: 100% 100%;
}
.seeDataBtn{
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  opacity: 0.3;
  z-index: 20;
  font-size: 16px;

  &:hover{
    opacity: 1;
  }
}
.paramsSelLayer{
  position: absolute;
  bottom: 0;
  right: 0;
  top: 40px;
  left: 0;
  padding: 10px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 10px;
  background-color: #111;
}
.hoverShowDesc{
  &:hover{
    .descIcon{
      opacity: 1;
    }
  }
}
.descIcon{
  position: absolute;
  z-index: 15;
  opacity: 0;

  transition: all 0.3s;
}
.coverContent{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}