.numberAnimationWrap {
  position: relative;
  display: inline-block;
  overflow: hidden;

  & div{
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    font-style: inherit;
  }

  .numberAnimation {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    transform-origin: 0 0;
    transition: top 0.2s;//过渡属性的名称 过渡动画所需的时间
  }

  .number,.numberAnimationWrapHidden {
    font-weight: bold;
    text-align: center;
  }

  .numberAnimationWrapHidden{
    visibility: hidden;
  }
}